<template>
  <DepartmentBadgeGroupModal :show.sync="modalShow" :company="company" :departmentList="departmentList" :multiple="multiple" @ok="departmentSelectorOk" @cancel="departmentSelectorCancel" @companyDeleted="departmentSelectorChanged"/>
</template>

<script>
import Vue from 'vue';
// import { objectClone } from '@/helpers';

export default Vue.extend({
  name: 'DepartmentsEditor',
  components: {
    DepartmentBadgeGroupModal: () => import('@/components/modal/DepartmentBadgeGroupModal')
  },
  data() {
    return {
      value: ''
      , modalShow: false
      , departmentList: []
      , multiple: false
      , company: null
    };
  },
  computed: {
  },
  methods: {
    getValue() {
      return this.value
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    departmentSelectorOk(modifieddepartmentList) {
      //Check array size
      if (!this.params.value ||
          modifieddepartmentList.length != this.params.value.length) {
        this.value = modifieddepartmentList;
        this.closePicker();
        return;
      }

      //Check UuId
      const originDepartments = this.params.value;
      const originIds = originDepartments.map(i => i.uuId);
      
      let hasChanged = false;
      for (let i = 0, len = modifieddepartmentList.length; i < len; i++) {
        if (!originIds.includes(modifieddepartmentList[i].uuId)) {
          hasChanged = true;
          break;
        }
      }
      if (hasChanged) {
        this.value = modifieddepartmentList;
      }
      this.closePicker;
    },
    departmentSelectorCancel({ modifiedList=[]}={}) {
      if (modifiedList.length > 0) {
        const deletedIds = this.modifiedList.filter(i => i.action =='DELETE').map(i => i.uuId)
        const updatedList = this.modifiedList.filter(i => i.action == 'UPDATE')
        const remaining = this.value.filter(i => !deletedIds.includes(i.uuId))
        for (const item of remaining) {
          const obj = updatedList.find(i => i.uuId == item.uuId)
          if (obj == null) {
            continue
          }
          item.name = obj.name
          // item.color = obj.color
        }
        this.value = remaining
      }
      this.closePicker()
    },
    prepareDepartmentList() {
      if(this.value != null) {
        for (const department of this.value) {
          this.departmentList.push({ uuId: department.uuId, name: department.name });
        }
      }
    },
    departmentSelectorChanged(/** { removedList=[]}={} **/) {
    }
    
  },

  created() {
    this.modalShow = true;
    if (this.params != null && this.params.data != null 
        && Array.isArray(this.params.data.companies) 
        && this.params.data.companies.length > 0) {
      this.company = this.params.data.companies[0] != null? this.params.data.companies[0] : null
    }
    if (this.params.multiple === true) {
      this.multiple = true;
    }
    this.value = this.params.value;
    this.prepareDepartmentList();
    
  }
})
</script>