<template>
  <LocationBadgeGroupModal :show.sync="modalShow" :locationList="locationList" :multiple="multiple" @ok="locationSelectorOk" @cancel="locationSelectorCancel" @companyDeleted="locationSelectorChanged"/>
</template>

<script>
import Vue from 'vue';
// import { objectClone } from '@/helpers';

export default Vue.extend({
  name: 'LocationsEditor',
  components: {
    LocationBadgeGroupModal: () => import('@/components/modal/LocationBadgeGroupModal')
  },
  data() {
    return {
      value: ''
      , modalShow: false
      , locationList: []
      , multiple: false
    };
  },
  computed: {
  },
  methods: {
    getValue() {
      return this.value
    },
    isPopup() {
      return true;
    },
    isCancelBeforeStart() {
      return false;
    },

    closePicker() {
      setTimeout(() => {
        this.params.api.stopEditing();
      }, 100);
    },
    locationSelectorOk(modifiedlocationList) {
      //Check array size
      if (!this.params.value ||
          modifiedlocationList.length != this.params.value.length) {
        this.value = modifiedlocationList;
        this.closePicker();
        return;
      }

      //Check UuId
      const originLocations = this.params.value;
      const originIds = originLocations.map(i => i.uuId);
      
      let hasChanged = false;
      for (let i = 0, len = modifiedlocationList.length; i < len; i++) {
        if (!originIds.includes(modifiedlocationList[i].uuId)) {
          hasChanged = true;
          break;
        }
      }
      if (hasChanged) {
        this.value = modifiedlocationList;
      }
      this.closePicker;
    },
    locationSelectorCancel({ modifiedList=[]}={}) {
      if (modifiedList.length > 0) {
        const deletedIds = this.modifiedList.filter(i => i.action =='DELETE').map(i => i.uuId)
        const updatedList = this.modifiedList.filter(i => i.action == 'UPDATE')
        const remaining = this.value.filter(i => !deletedIds.includes(i.uuId))
        for (const item of remaining) {
          const obj = updatedList.find(i => i.uuId == item.uuId)
          if (obj == null) {
            continue
          }
          item.name = obj.name
          // item.color = obj.color
        }
        this.value = remaining
      }
      this.closePicker()
    },
    prepareCompanyList() {
      if(this.value != null) {
        for (const company of this.value) {
          this.locationList.push({ uuId: company.uuId, name: company.name });
        }
      }
    },
    locationSelectorChanged(/**{ removedList=[]}={} */) {
      
    }
    
  },

  created() {
    this.modalShow = true;
    if (this.params.multiple === true) {
      this.multiple = true;
    }
    this.value = this.params.value;
    this.prepareCompanyList();
    
  }
})
</script>